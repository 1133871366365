/**
 * This file contains utilities to work with dates.
 *
 * The code assumes that
 * datetimes are ISO 8601, i.e., 2020-08-10T23:45:55Z
 * dates ISO 8601, i.e., 2020-08-10
 *
 * Moment is used for advanced date computations and l18n.
 * Non-trivial logic is abstracted in utility functions.
 *
 * At the time of writing, the authors are unaware of a possibility to globally adjust
 * the timezone for Linux, MacOS and Windows.
 * To achieve snapshot invariance w.r.t. the system's timezone, this file
 * hosts the global variable `utcOffset`. If it is set, it overrides getUtcOffset().
 * This enables invariance of the formatting functions in unit- and snapshot tests.
 *
 */

import moment, {Moment, unitOfTime} from 'moment-timezone'
import 'moment/locale/bs'

import 'moment/locale/de'
import 'moment/locale/de-at'
import 'moment/locale/de-ch'
import 'moment/locale/en-au'
import 'moment/locale/en-gb'
import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/ka'
import 'moment/locale/nl'
import 'moment/locale/pl'
import 'moment/locale/es'
import 'moment/locale/cs'
import 'moment/locale/ru'
import 'moment/locale/zh-cn'
import 'moment/locale/zh-hk'
import 'moment/locale/nb'
import 'moment/locale/sv'
import 'moment/locale/da'
import 'moment/locale/ro'

// FIXME: Not everything delivered by language field is supported by moment.
// Instead of supporting everything we should probably implement some
// locale list to support with fallbacks to defaults.
export const getLocale = () => {
  if (window.navigator.languages) {
    return window.navigator.languages[0]
  }
  return window.navigator.language
}

/**
 * Global variable for utcOffset. It should only be accessed via getUctOffset()
 * and setUtcOffset()
 */
let utcOffset: number | undefined

/**
 * Returns a utcOffset
 *
 * In general, the utcOffset is computed from the system time and system timezone.
 * It can change during the runtime of the app, e.g., when CEST from winter to summer time.
 * One can set a fixed value via setUtcOffset()
 */
export const getUtcOffset = (date: string | Date | Moment = moment()): number =>
  utcOffset ?? moment(date).utcOffset()

/**
 * Sets the global variable utcOffset.
 *
 * Then, getUtcOffset() will always return this value
 * To reset use setUtcOffset(undefined)
 */
export const setUtcOffset = (value: number | undefined) => {
  utcOffset = value
}

/**
 * Converts a YYYY-MM-DD string to a Moment object with an utcOffset
 *
 * **Examples**
 *
 * dateStringToMoment('2019-07-26', 0).toIsoString(): "2019-07-26T00:00:00Z"
 * dateStringToMoment('2019-07-26', 120).toIsoString(): "2019-07-26T00:00:00+02:00"
 *
 * See the tests for more examples.
 *
 * @param date Strings of the form YYYY-MM-DD
 * @param utcOffset e.g. 120
 * @returns Moment object
 *
 */
export const convertDateStringToMoment = (
  date: string | Date | Moment,
  utcOffset: number = getUtcOffset()
): Moment => moment.utc(date).utcOffset(utcOffset, true)

// dynamic load locale for momentJS
// import(`moment/locale/${getLocale().toLowerCase()}`)

/**
 * Helper function used in other functions in this file to treat date strings YYYY-MM-DD
 * differently from datetime strings YYYY-MM-DDTHH:mm:ssZ
 *
 * The logic depends on whether it is a
 *
 * @param date
 * @param utcOffset
 * @returns Moment in appropriate utcOffset
 */
export const _dateStringToUtc = (date: string | Date | Moment, utcOffset: number): Moment => {
  if (typeof date === 'string' && date.length === 10) {
    return moment(date)
  }
  return moment.utc(date).utcOffset(utcOffset)
}

export function convertMinsToHrsMins(minutes: number | undefined) {
  if (!minutes) {
    return '00:00'
  }

  const h = Math.floor(minutes / 60)
  const m = minutes % 60
  const hDisplay = h < 10 ? '0' + h : h
  const mDisplay = m < 10 ? '0' + m : m
  return `${hDisplay}:${mDisplay}`
}

/**
 * Formats a string of the form YYYY-MM-DD in current locale.
 *
 * The output is independent of utcOffset.
 *
 * **Examples**
 *
 * dateStringFormatter('2019-07-26', 'en'): "07/26/2019"
 * dateStringFormatter('2019-07-26', 'de'): "26.07.2019"
 *
 * See the tests for more examples.
 *
 * @param date Strings of the form YYYY-MM-DD
 * @param locale  Uses system locale if not provided
 * @returns String of the date(-time) in the current locale and utcOffset
 *
 */
export const dateStringFormatter = (date: string, locale = getLocale()): string =>
  moment.utc(date).utcOffset(0).locale(locale).format('L')

/**
 * Formats a date(-time) object in current locale and utcOffset
 *
 * The result is independent of utcOffset for strings of the form YYYY-MM-DD.
 * For datetime strings the result depends on utcOffset.
 *
 * **Examples**
 *
 * dateFormatter('2019-07-26T00:00:00Z', 'de', 0): "26.07.2019"
 * dateFormatter('2019-07-26T00:00:00Z', 'de', 60): "26.07.2019"
 * dateFormatter('2019-07-26T00:00:00Z', 'de', -60): "25.07.2019"
 * dateFormatter('2019-07-26T00:00:00Z', 'en_US', 0): "07/26/2019"
 *
 * dateFormatter('2019-07-26', 'de', 0): "26.07.2019"
 * dateFormatter('2019-07-26', 'de', 60): "26.07.2019"
 * dateFormatter('2019-07-26', 'de', -60): "26.07.2019"
 * dateFormatter('2019-07-26', 'en_US', 0): "07/26/2019"
 *
 * See the tests for more examples.
 *
 * @param date ISO 8601 strings (YYYY-MM-DD, YYYY-MM-DDTHH:mm:ssZ) or Date or Moment objects
 * @param locale  Uses system locale if not provided
 * @param utcOffset  Uses system utcOffset if not provided
 * @returns  String of the date in the specified locale and utcOffset
 */
export const dateFormatter = (
  date: Date | string | Moment,
  locale = getLocale(),
  utcOffset = getUtcOffset()
) => _dateStringToUtc(date, utcOffset).locale(locale).format('L')

/**
 * Formats a datetime object in current locale and utcOffset
 *
 * The result is independent of utcOffset for strings of the form YYYY-MM-DD.
 * For datetime strings the result depends on utcOffset.
 *
 * **Examples**
 *
 * timeFormatter('2019-07-26T00:00:00Z', 'de', 120): "22:00"
 * timeFormatter('2019-07-26T00:00:00Z', 'de', 0): "00:00"
 *
 * See the tests for more examples.
 *
 * @param date  ISO 8601 strings (YYYY-MM-DD, YYYY-MM-DDTHH:mm:ssZ) or Date or Moment objects
 * @param locale  Uses system locale if not provided
 * @param utcOffset  Uses system utcOffset if not provided
 * @returns String of the time in the current locale and utcOffset
 */
export const timeFormatter = (
  date: string | Date | Moment,
  locale = getLocale(),
  utcOffset?: number
): string =>
  _dateStringToUtc(date, utcOffset !== undefined ? utcOffset : getUtcOffset(date))
    .locale(locale)
    .format('LT')

/**
 * Formats a datetime object in current locale and utcOffset
 *
 * Strings of the form YYYY-MM-DD are independent of the current utcOffset.
 *
 * **Examples**
 *
 * datetimeFormatter('2019-07-26T00:00:00Z', 'de', -120): "2019 22:00"
 * datetimeFormatter('2019-07-26T00:00:00Z', 'de', 0): "00:00"
 *
 * See the tests for more examples.
 *
 * @param date  ISO 8601 strings (YYYY-MM-DD, YYYY-MM-DDTHH:mm:ssZ) or Date or Moment objects
 * @param locale  Uses system locale if not provided
 * @param utcOffset  Uses system utcOffset if not provided
 * @returns String of the date and time in the current locale and utcOffset
 */
export const datetimeFormatter = (
  date: string | Date | Moment,
  locale: string = getLocale(),
  utcOffset = getUtcOffset()
): string => `${dateFormatter(date, locale, utcOffset)} ${timeFormatter(date, locale, utcOffset)}`

export const MOMENTJS_DATE_FORMAT = 'YYYY-MM-DD'

// format string for 2020-10-21T00:45:00+01:00 and 2020-10-21T00:45:00Z for UTC
export const getMomentDatetimeFormatString = (utcOffset: number): string =>
  `YYYY-MM-DD[T]HH:mm:ss${utcOffset === 0 ? '[Z]' : 'Z'}`

/**
 * Converts a date to an ISO 8601 string in utcOffset
 *
 * **Examples**
 *
 * isoDatetimeFormatter('2020-10-21T00:45:00+01:00', 0): "2020-10-20T23:45:00Z"
 *
 * @param date e.g. 2020-08-10T10:45:55Z
 * @param utcOffset e.g. 120
 * @returns ISO 8601 string
 */
export const isoDatetimeFormatter = (
  date: string | Date | Moment,
  utcOffset = getUtcOffset()
): string => moment.utc(date).utcOffset(utcOffset).format(getMomentDatetimeFormatString(utcOffset))

/**
 * Converts a date to a YYYY-MM-DD string in utcOffset
 *
 * **Examples**
 *
 * isoDateFormatter('2020-10-21T00:01:00+02:00', 0): "2020-10-20"
 *
 * @param date e.g. 2020-08-10T10:45:55Z
 * @param utcOffset e.g. 120
 * @returns YYYY-MM-DD string
 */
export const isoDateFormatter = (
  date: string | Date | Moment,
  utcOffset = getUtcOffset()
): string => moment.utc(date).utcOffset(utcOffset).format(MOMENTJS_DATE_FORMAT)

/**
 * Convenience function for isoDateFormatter(date, 0)
 *
 * **Examples**
 *
 * isoUtcDateFormatter('2020-10-21T00:01:00+02:00'): "2020-10-20"
 *
 * @param date e.g. 2020-08-10T10:45:55Z
 * @returns YYYY-MM-DD string
 */
export const isoUtcDateFormatter = (date: string | Date | Moment): string =>
  isoDateFormatter(date, 0)

/**
 * Convenience function for isoDatetimeFormatter(date, 0)
 *
 * **Examples**
 *
 * isoDatetimeFormatter('2020-10-21T00:45:00+01:00', 0): "2020-10-20T23:45:00Z"
 *
 * @param date e.g. 2020-08-10T10:45:55Z
 * @returns YYYY-MM-DDTHH:mm:ssZ string
 */
export const isoUtcDatetimeFormatter = (date: string | Date | Moment): string =>
  isoDatetimeFormatter(date, 0)

/**
 *
 * @param date Date
 * @returns start of the previous year in 'YYYY-MM-DD' format
 */
export const isoUtcStartPrevYear = (date: string | Date | Moment): string =>
  moment.utc(date).subtract(1, 'year').startOf('year').format(MOMENTJS_DATE_FORMAT)

/**
 * Returns day name in locale
 *
 * **Examples**
 *
 * dayNameFormatter('2020-08-11', 'de', 0): "Dienstag"
 * dayNameFormatter('2020-08-11', 'de', -120): "Dienstag"
 * dayNameFormatter('2020-08-11T00:00:00Z', 'de', -120): "Montag"
 *
 * @param date  e.g. '2020-10-10T10:45:55Z'
 * @param locale  e.g. 'de_DE'
 * @param utcOffset e.g. 120
 * @returns full name of the day in locale
 */
export const dayNameFormatter = (
  date: Date | string | Moment,
  locale: string = getLocale(),
  utcOffset = getUtcOffset()
): string => _dateStringToUtc(date, utcOffset).locale(locale).format('dddd')

/**
 * Returns short day name in locale
 *
 * **Examples**
 *
 * dayNameFormatter('2020-08-11', 'de', 0): "Di."
 * dayNameFormatter('2020-08-11', 'de', -120): "Di."
 * dayNameFormatter('2020-08-11T00:00:00Z', 'de', -120): "Mo."
 *
 * @param date  e.g. '2020-10-10T10:45:55Z'
 * @param locale  e.g. 'de_DE'
 * @param utcOffset e.g. 120
 * @returns short name of the day in locale
 *
 */
export const shortDayNameFormatter = (
  date: Date | string | Moment,
  locale: string = getLocale(),
  utcOffset = getUtcOffset()
): string => _dateStringToUtc(date, utcOffset).locale(locale).format('ddd')

/**
 * Returns month name in locale
 *
 *
 * **Examples**
 *
 * See unit tests.
 *
 * @param date  e.g. '2020-10-10T10:45:55Z'
 * @param locale  e.g. 'de_DE'
 * @param utcOffset e.g. 120
 * @returns full name of the month in locale
 */
export const monthNameFormatter = (
  date: Date | string,
  locale: string = getLocale(),
  utcOffset = getUtcOffset()
): string => _dateStringToUtc(date, utcOffset).locale(locale).format('MMMM')

/**
 * Returns short month name in locale
 *
 * **Examples**
 *
 * See unit tests.
 *
 * @param date  e.g. '2020-10-10T10:45:55Z'
 * @param locale  e.g. 'de_DE'
 * @param utcOffset e.g. 120
 * @returns short name of the day in locale
 */
export const shortMonthNameFormatter = (
  date: Date | string,
  locale: string = getLocale(),
  utcOffset = getUtcOffset()
): string => _dateStringToUtc(date, utcOffset).locale(locale).format('MMM')

/**
 * Returns day number as string
 *
 * **Examples**
 *
 * See unit tests.
 *
 * @param date  e.g. '2020-10-10T10:45:55Z'
 * @param locale  e.g. 'de_DE'
 * @param utcOffset e.g. 120
 * @returns day
 */
export const dayFormatter = (
  date: Date | string,
  locale: string = getLocale(),
  utcOffset = getUtcOffset()
): string => _dateStringToUtc(date, utcOffset).locale(locale).format('DD')

/**
 * Returns month number as string
 *
 * **Examples**
 *
 * See unit tests.
 *
 * @param date  e.g. '2020-10-10T10:45:55Z'
 * @param locale  e.g. 'de_DE'
 * @param utcOffset e.g. 120
 * @returns month
 */
export const monthFormatter = (
  date: Date | string,
  locale: string = getLocale(),
  utcOffset = getUtcOffset()
): string => _dateStringToUtc(date, utcOffset).locale(locale).format('MM')

/**
 * Returns year number as string
 *
 * **Examples**
 *
 * See unit tests.
 *
 * @param date  e.g. '2020-10-10T10:45:55Z'
 * @param locale  e.g. 'de_DE'
 * @param utcOffset e.g. 120
 * @returns year
 */
export const yearFormatter = (
  date: Date | string,
  locale: string = getLocale(),
  utcOffset = getUtcOffset()
): string => _dateStringToUtc(date, utcOffset).locale(locale).format('YYYY')

export const beginningOfToday = (locale: string = getLocale()): Moment =>
  moment().locale(locale).startOf('day')

export const nextSixDays = (locale: string = getLocale()): Moment =>
  moment().locale(locale).add(6, 'day').endOf('day')

export const thisWeekDates = (locale: string = getLocale()): [Moment, Moment] => [
  moment().locale(locale).startOf('week'),
  moment().locale(locale).endOf('week')
]

export const lastFourteenDays = (locale: string = getLocale()): [Moment, Moment] => [
  moment().locale(locale).subtract(13, 'day').startOf('day'),
  moment().locale(locale).endOf('day')
]

export const lastThirtyDays = (locale: string = getLocale()): [Moment, Moment] => [
  moment().locale(locale).subtract(29, 'day').startOf('day'),
  moment().locale(locale).endOf('day')
]

export const lastSixtyDays = (locale: string = getLocale()): [Moment, Moment] => [
  moment().locale(locale).subtract(59, 'day').startOf('day'),
  moment().locale(locale).endOf('day')
]

export const lastNinetyDays = (locale: string = getLocale()): [Moment, Moment] => [
  moment().locale(locale).subtract(89, 'day').startOf('day'),
  moment().locale(locale).endOf('day')
]

export const prevMonthDates = (locale: string = getLocale()): [Moment, Moment] => [
  moment().locale(locale).subtract(1, 'month').startOf('month'),
  moment().locale(locale).subtract(1, 'month').endOf('month')
]

export const prevQuarterDates = (locale: string = getLocale()): [Moment, Moment] => [
  moment().locale(locale).subtract(3, 'month').startOf('month'),
  moment().locale(locale).subtract(1, 'month').endOf('month')
]

export const oneWeekDates = (locale = 'en'): [Moment, Moment] => [
  moment().locale(locale).startOf('day'),
  moment().locale(locale).add(6, 'days').endOf('day')
]

export const upcomingDates = (locale: string = getLocale()): [Moment, Moment] => [
  moment().locale(locale).add(1, 'days').startOf('day'),
  moment().locale(locale).add(7, 'days').endOf('day')
]

export const ongoingDates = (locale: string = getLocale()): [Moment, Moment] => [
  moment().locale(locale).startOf('day'),
  moment().locale(locale).endOf('day')
]

export const oneYear = (locale: string = getLocale()): [Moment, Moment] => [
  moment().locale(locale).subtract(1, 'year').startOf('day'),
  moment().locale(locale).endOf('day')
]

export const oneMonth = (locale: string = getLocale()): [Moment, Moment] => [
  moment().locale(locale).subtract(1, 'month').startOf('day'),
  moment().locale(locale).endOf('day')
]
export const oneQuarter = (locale: string = getLocale()): [Moment, Moment] => [
  moment().locale(locale).subtract(3, 'month').startOf('day'),
  moment().locale(locale).endOf('day')
]

export const dateRangeExceedsOneYear = (startDate: Date, endDate: Date): boolean => {
  const a = moment(startDate)
  const b = moment(endDate)
  return a.diff(b, 'year') < 0
}

export const dateRangeFormatter = (
  startDate: Date | string | Moment,
  endDate: Date | string | Moment,
  locale = getLocale()
) =>
  `${dateFormatter(startDate, locale, getUtcOffset(startDate))} - 
   ${dateFormatter(endDate, locale, getUtcOffset(endDate))}`

export const getTimezoneName = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone

export const getDateRangeInDays = (startTimestamp: string, endTimestamp: string): string => {
  const startAsDate = new Date(startTimestamp).getTime()
  const endAsDate = new Date(endTimestamp).getTime()

  return Math.floor((endAsDate - startAsDate) / 1000 / 60 / 60 / 24 + 1).toString()
}

/**
 * Adds to a given date an offset by a unit.  If desired to offset into the past then the offset must be negative.
 */
export const shiftDateTime = (
  date: Moment | Date | string,
  offset: number,
  unit: unitOfTime.DurationConstructor
) => moment(date).add(offset, unit)

/**
 * Format the date using a specified time zone.
 * @param date
 * @param timeZone
 * @param format
 * @param locale
 */
export const formatTimeZoneDate = (
  date: Date | string | number,
  timeZone: string,
  format: string,
  locale: string
): string => moment.utc(date).locale(locale).tz(timeZone).format(format)

/**
 * @param date Moment object
 * @param locale language string
 * @returns formatted localized day month date
 * Examples:
 * '2020-08-11T00:00:00Z' => 11.08 for de
 * '2020-08-11T00:00:00Z' => 08/11 for en
 */
export const formatLocalizedDateMonth = (date: Moment, locale: string) => {
  const format = moment.localeData(locale).longDateFormat('L')
  const adjustedFormatL = format.replace(/Y/g, '').replace(/^\W|\W$|\W\W/, '')
  return date.format(adjustedFormatL)
}

/**
 * @param date Moment object
 * @param locale language string
 * @returns formatted localized day month year date
 * Examples:
 * '2020-08-11T00:00:00Z' => 11.08.20 for de
 * '2020-08-11T00:00:00Z' => 08/11/20 for en
 */
export const formatLocalizedDateWithShortYear = (date: Moment, locale: string) => {
  const format = moment.localeData(locale).longDateFormat('L')
  const adjustedFormatL = format.replace(/(YYYY)/g, 'YY')
  return date.format(adjustedFormatL)
}

/**
 *  Provide a human readable date range, eg, 09/14/2023 – 10/13/2023 --> 14th September until 13th October
 * @param startDate
 * @param endDate
 * @param locale
 * @returns human readable date range
 */
export const humanReadableDateRange = (
  startDate: Date | string | undefined,
  endDate: Date | string | undefined
): string => {
  if (!startDate || !endDate) return ''

  // if dates are same, ie, startDate=endDate
  if (moment(startDate).isSame(endDate)) {
    // when year is not current year
    if (!moment(startDate).isSame(moment(), 'year'))
      return `on ${convertDateStringToMoment(startDate).format('Do MMMM YYYY')}`

    return `on ${convertDateStringToMoment(startDate).format('Do MMMM')}`
  }
  // if year is same
  if (moment(startDate).isSame(endDate, 'year'))
    return `between ${convertDateStringToMoment(startDate).format(
      'Do MMMM'
    )} until ${convertDateStringToMoment(endDate).format('Do MMMM')}`

  // if year is different
  if (!moment(startDate).isSame(endDate, 'year'))
    return `between ${convertDateStringToMoment(startDate).format(
      'Do MMMM YYYY'
    )} until ${convertDateStringToMoment(endDate).format('Do MMMM YYYY')}`

  return ''
}
